import "/node_modules/bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <div>

      <p className="lead">
          Learn React kjhkashfkajhsfkfh kasjfhkashfkajshfk  kajshdkjahkdjha kdkjhasdk jashkjh 
      </p>
      </div>
  );
}

export default App;
